<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-update-payout" centered hide-footer size="lg" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="uil uil-receipt-alt text-primary"></i> Process Payout #{{ updateData.payout_id }}
        </h5>
      </template>
      <div class="row align-items-center">
        <div class="col-12">
          <h5 class="font-size-14 fw-medium  mb-4 custom_top_data_bar p-3 rounded"> Merchant Details</h5>
          <div class="mb-4 row">
            <label class="col-lg-3 col-12 col-form-label py-0">Merchant</label>
            <div class="col-lg-9 col-12 col-form-label py-0">
              <span v-if="updateData.merchant_info">#{{ updateData.merchant_info.merchant_id }} {{  updateData.merchant_info.merchant_name }}</span>
            </div>
          </div>
          <h5 class="font-size-14 fw-medium text-white mb-4 custom_top_data_bar px-3 py-3 rounded"> Recipient Details</h5>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12">Payout Currency</label>
            <div class="col-lg-9 col-12">
              <span class="badge bg-soft-primary mb-0 d-inline-block font-size-14">{{ updateData.payout_currency }}</span>
            </div>
          </div>
          <div class="mb-3 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-3 col-12">Cryptocurrency</label>
            <div class="col-lg-9 col-12">{{ updateData.receiver_bank }}</div>
          </div>
          <div class="mb-3 row" v-else-if="updateData.receiving_bank">
            <label class="col-lg-3 col-12">Receiving Bank</label>
            <div class="col-lg-9 col-12">{{ updateData.receiving_bank }}</div>
          </div>
          
          
          <div class="mb-3 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-3 col-12">Network</label>
            <div class="col-lg-9 col-12">{{ updateData.receiver_account_name }}</div>
          </div>
          <div class="mb-3 row" v-else>
            <label class="col-lg-3 col-12">Beneficiary Name</label>
            <div class="col-lg-9 col-12">{{ updateData.receiver_account_name }}</div>
          </div>
          <div class="mb-3 row" v-if="updateData.payout_currency=='USDT'">
            <label class="col-lg-3 col-12">Wallet Address</label>
            <div class="col-lg-9 col-12">{{ updateData.receiver_account_number }}</div>
          </div>
          <div class="mb-3 row" v-else>
            <label class="col-lg-3 col-12">Beneficiary Account No.</label>
            <div class="col-lg-9 col-12">{{ updateData.receiver_account_number }}</div>
          </div>
          <div v-if="updateData.extra_field_1">
            <div class="mb-3 row" v-if="updateData.extra_field_1.enabled">
              <label class="col-lg-3 col-12">{{ updateData.extra_field_1.name }}</label>
              <div class="col-lg-9 col-12">{{ updateData.extra_field_1.value }}</div>
            </div>
          </div>
          <div v-if="updateData.extra_field_2">
            <div class="mb-3 row" v-if="updateData.extra_field_2.enabled">
              <label class="col-lg-3 col-12">{{ updateData.extra_field_2.name }}</label>
              <div class="col-lg-9 col-12">{{ updateData.extra_field_2.value }}</div>
            </div>
          </div>
          <div v-if="updateData.extra_field_3">
            <div class="mb-3 row" v-if="updateData.extra_field_3.enabled">
              <label class="col-lg-3 col-12">{{ updateData.extra_field_3.name }}</label>
              <div class="col-lg-9 col-12">{{ updateData.extra_field_3.value }}</div>
            </div>
          </div>
          <div class="mb-3 row" v-if="updateData.payout_amount">
            <label class="col-lg-3 col-12">Payout Amount</label>
            <div class="col-lg-9 col-12 fw-medium text-dark">{{ updateData.contract_currency }} <span v-if="updateData.payout_amount">{{ convertCurrencyFormat(updateData.payout_amount,true) }}</span> </div>
          </div>
          <div class="mb-3 row" v-if="updateData.payout_amount">
            <label class="col-lg-3 col-12">Total Charges</label>
            <div class="col-lg-9 col-12"> 
             <span class="fw-medium text-dark">{{ updateData.contract_currency }}<span v-if="updateData.payout_total_charges">
              {{ convertCurrencyFormat(updateData.payout_total_charges,true) }}</span></span>
              <small class="fw-normal d-block text-muted font-size-14">Payout Rate and Extra Charges ({{ updateData.payout_rate }}% + {{ updateData.contract_currency }} {{ updateData.payout_extra_charges }})</small>
           
            </div>
          </div>
          <div class="mb-3 row" v-if="updateData.payout_amount">
            <label class="col-lg-3 col-12">Amount Deducted</label>
            <div class="col-lg-9 col-12"> 
             <span class="fw-medium text-primary">{{ updateData.contract_currency }}
              <span v-if="updateData.amount_deducted">
                  {{convertCurrencyFormat(updateData.amount_deducted,true)}}
              </span>
            </span>
            </div>
          </div>

          <div class="mb-3 row" v-if="updateData.transaction_description">
            <label class="col-lg-3 col-12">Transaction Description</label>
            <div class="col-lg-9 col-12 text-muted">{{updateData.transaction_description}}</div>
          </div>

          <div class="mb-3 row" v-if="updateData.merchant_callback_url">
            <label class="col-lg-3 col-12 mb-1 mb-lg-2">Callback URL</label>
            <div class="col-lg-9 col-12 text-muted">{{updateData.merchant_callback_url}}</div>
          </div>
          <hr>
          
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Status</label>
            <div class="col-lg-9 col-12">
              <select class="form-select" v-model="seletedStatus" v-if="updateData.status=='pending'">
                <option value="reserved">Reserved</option>
                <option value="rejected">Rejected</option>
              </select>
              <select class="form-select" v-model="seletedStatus" v-if="seletedStatus!=='pending' && updateData.status!=='pending' ">
                <option value="complete">Completed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>
          <div class="mb-3 row" v-if="seletedStatus=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction ID</label>
            <div class="col-lg-9 col-12">
              <input v-model.trim="formSuccessData.transactionId" type="text" class="form-control" placeholder="Transaction ID" :class="{
                    'is-invalid':
                    modalSubmit && $v.formSuccessData.transactionId.$error,
                  }" name="TransactionId" />
              <div v-if="modalSubmit && $v.formSuccessData.transactionId.$error" class="invalid-feedback fw-normal">
                <span v-if="!$v.formSuccessData.transactionId.required">Transaction ID is required.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 row" v-if="seletedStatus=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Payment Gateway</label>
            <div class="col-lg-9 col-12">
                <multiselect
                  v-model="selectedPg"
                  :options="
                  pgList.filter((item) => {
                    return (
                      item.currency.includes(updateData.payout_currency.replaceAll('_NATIVE',''))
                    );
                  })"
                  label="payment_gateway"
                  class="mb-1"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  select-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @input="updatePg()"
                  :class="{ 'is-invalid': modalSubmit && $v.formSuccessData.paymentGateway.$error}"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.id!==''">
                    <span v-if="props.option.status!==1" 
                    class="text-danger me-1">&#9679;</span>
                    <span v-else class="text-success me-1">&#9679;</span>
                    <span class="option__title">{{ props.option.payment_gateway_name }} ({{ props.option.currency }} {{ convertCurrencyFormat(props.option.pg_balance, true) }} ) 
                      <span class="badge text-bg-primary ms-1 font-size-12">
                        {{props.option.payout_rate}}%
                        + {{ convertCurrencyFormat(props.option.payout_extra_charges, true) }}
                      </span>
                    </span>
                  </span>
                  <span v-else class="font-size-14">
                    {{ props.option.payment_gateway_name }}
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <span v-if="props.option.id!==''">
                    <span v-if="props.option.status!==1" 
                    class="text-danger me-1">&#9679;</span>
                    <span v-else class="text-success me-1">&#9679;</span>
                    <span class="option__title">{{ props.option.payment_gateway_name }} ({{ props.option.currency }} {{ convertCurrencyFormat(props.option.pg_balance, true) }} ) 
                      <span class="badge text-bg-primary ms-1 font-size-12">
                        {{props.option.payout_rate}}%
                        + {{ convertCurrencyFormat(props.option.payout_extra_charges, true) }}
                      </span>
                      
                    </span>
                  </span>
                  <span v-else class="font-size-14">
                    {{ props.option.payment_gateway_name }}
                  </span>
                </template>
                <span slot="noResult">Oops! No Payment Gateway found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formSuccessData.paymentGateway.$error" class="invalid-feedback">
                  <span v-if="!$v.formSuccessData.paymentGateway.required">Payment gateway is required.</span>
                </div>
              </div>
                  
          </div>
           <div class="mb-3 row" v-if="seletedStatus=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction Remark</label>
            <div class="col-lg-9 col-12">
              <textarea v-model.trim="formSuccessData.transactionRemarks" type="text" class="form-control" placeholder="Transaction Remark" rows="3" ></textarea>
            </div>
          </div>
          <div class="mb-3 row" v-if="seletedStatus=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction Receipts <span class="d-block font-size-13 fw-normal text-muted">(Max. {{maxFiles}} Files)</span> 
              <div><a href="https://combinepdf.com" class="fw-normal" target="_blank"><u>https://combinepdf.com</u></a></div>
            </label>
            <div class="col-lg-9 col-12">
              <input class="form-control" :class="{ 'border border-danger animate__animated animate__shakeX is-invalid': modalSubmit && $v.formSuccessData.transactionReceipt.$error}" multiple accept="application/pdf, image/*" type="file" id="formFile" @change="onChangePDF" :disabled="disabled || currentFiles==maxFiles">

              <div v-if="modalSubmit && $v.formSuccessData.transactionReceipt.$error" class="invalid-feedback">
                <span v-if="!$v.formSuccessData.transactionReceipt.required">Please upload transaction receipts.</span>
              </div>
              <div class="mt-3">
                <a :href="value.link" class="mb-1 d-block" v-for="(value,index) in formSuccessData.transactionReceipt" :key="index" target="_blank"><i class="uil uil-receipt-alt text-primary"></i> {{ value.file_name}} ({{ value.file_size }})</a>
              </div>
              <div class="mt-2">
                <div class="Animated progress rounded bg-white" style="height:16px" role="progressbar" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13" :style="`width: ${uploadProgress}%`">{{uploadProgress}}%</div>
              </div>
              </div>

              <div id="preview" class="mb-3 rounded bg-light border-muted p-2 position-relative text-center d-none" :class="{
                    'border border-danger animate__animated animate__shakeX is-invalid': modalSubmit && $v.formSuccessData.transactionReceipt.$error
                  }" @click="currentImageSelectedFunc('front')">
                <img v-if="formSuccessData.transactionReceipt" class="rounded img-fluid mb-2" :src="formSuccessData.transactionReceipt" />
                <!-- <img v-else src="" @click="$refs.fileInput.click()"> -->
                <div v-else class="d-flex align-items-center p-3 m-2">
                  <div class="text-center w-100">
                    <h4 class="fw-normal text-primary text-center">
                      <i class="uil uil-receipt-alt mb-3 display-1"></i>
                      <small class="font-size-14 d-block text-body">Upload Receipt</small>
                    </h4>
                  </div>
                </div>
                <div class="position-relative text-center">
                  

                  <image-Uploader :class="['input-group']" ref="fileInput" id="image-input" :maxWidth="1600" :maxHeight="1600" :quality="0.8" outputFormat="base64" :multiple="false" accept="image/png, image/gif, image/jpeg, image/jpg" :hidden="true" @onUpload="startFiles" @onComplete="viewAllFiles"></image-Uploader>
                  <div>
                    <button class="btn mb-2 ms-2 btn-outline-light bg-light text-muted overflow-hidden fileInput" v-if="formSuccessData.transactionReceiptFilename">
                      <i class="mdi mdi-file text-primary"></i> {{formSuccessData.transactionReceiptFilename}}
                    </button>
                  </div>
                  <button class="btn btn-info mb-3">
                    <i class="uil uil-upload"></i> Upload Receipt </button>
                </div>
              </div>
              <!-- {{ transactionReceipt }}
                {{ transactionReceiptFile }}
                {{ transactionReceiptFilename }} -->
            </div>
          </div>
          <div class="mb-3 row" v-if="seletedStatus=='rejected'">
            <label class="col-lg-3 col-12 col-form-label">Reasons for Rejection</label>
            <div class="col-lg-9 col-12 fw-medium text-primary">
              <textarea rows="4" v-model.trim="formRejectedData.reasonForRejected" type="text" class="form-control mb-1" :class="{
                    'is-invalid':
                    modalSubmit && $v.formRejectedData.reasonForRejected.$error,
                  }" placeholder="Reasons for Rejection" name="reasonForRejection"></textarea>
              <div v-if="modalSubmit && $v.formRejectedData.reasonForRejected.$error" class="invalid-feedback fw-normal">
                <span v-if="!$v.formRejectedData.reasonForRejected.required">Reasons for rejection is required.</span>
              </div>
              <div class="mt-3">
              <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Insufficient Balance At Contract. '"><i class="uil uil-plus-circle"></i> Insufficient Balance At Contract</button>
              <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Incorrect Account Name. '"><i class="uil uil-plus-circle"></i> Incorrect Account Name</button>
              <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Incorrect Account Number. '"><i class="uil uil-plus-circle"></i> Incorrect Account Number</button>
              <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Account Does Not Exist. '"><i class="uil uil-plus-circle"></i> Account Does Not Exist</button>
              <button class="btn btn-sm bg-soft-secondary text-secondary me-1 mb-2" @click="formRejectedData.reasonForRejected=''"><i class="uil uil-times-circle"></i> Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-update-payout'].hide()">Cancel</button>
            <button type="button" class="btn fw-medium btn-info" @click="updateSettlement" :disabled="modalLoading || disabled">
              <span v-if="seletedStatus=='reserved'">
                <span v-if="modalLoading"> Reserving...</span>
                <span v-else> Reserved </span>
              </span>
              <span v-else>
                <span v-if="modalLoading"> Updating...</span>
                <span v-else> Update </span>
              </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import Multiselect from "vue-multiselect";
import {
  required
} from "vuelidate/lib/validators";
import ImageUploader from "@/components/imageUploader";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
// const validWebsiteUrl = (value) => {
//   console.log(value);
//   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
//   return regex.test(value);
// }
// const validUsername = (value) => {
//   const regex = /^[a-z0-9]+$/;
//   return regex.test(value);
// };
//  const equalDefault = (value) => {
//   return value !== -1;
//   }
/**
 * Starter page
 */
const pgDefault = { "id": "", "payment_gateway":"", "payment_gateway_name": "Select Payment Gateway", "payment_gateway_code": "", "currency":"", "status":"", "pg_balance": 0,  "settlement_rate": 0, "settlement_extra_charges": 0 } 

export default {
  components: {
    Common,
    Multiselect,
    ImageUploader
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      updateData: {},
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      seletedStatus: "",
      currentImageSelected: "front",
      fileCompressing: false,
      returnData: [],
      selectedPg:pgDefault,
      pgList:[pgDefault],
      formSuccessData: {
        transactionId: "",
        transactionReceipt: [],
        transactionRemarks: "",
        paymentGateway: "",
      },
      formRejectedData: {
        reasonForRejected: "",
      },
      uploadProgress:0,
      currentFiles: 0,
      uploadHowManyPerTime:0,
      maxFiles: 3,
    };
  },
  validations: {
    formSuccessData: {
      transactionReceipt: {
        required,
      },
      transactionId: {
        required,
      },
      paymentGateway: {
        required,
      },
    },
    formRejectedData: {
      reasonForRejected: {
        required,
      }
    }
  },
  middleware: "authentication",
  async mounted() {
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
  },
  created() {},
  methods: {
    async onChangePDF(e) {
      console.log(this.currentFiles,this.maxFiles);
      console.log(e.target.files.length);
      if ((e.target.files.length+this.currentFiles)> this.maxFiles) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `The system allows a maximum of ${this.maxFiles} file uploads.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
        e.target.value = '';
        return; // Abort further processing if the file limit is exceeded
      }

      // Iterate over each file
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        // Check if the file size is greater than 5MB
        if (file.size > 5 * 1024 * 1024) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `The file ${file.name} exceeds the 5MB size limit.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          e.target.value = '';
          return; // Abort further processing if any file size exceeds 5MB
        }

        // Upload each file
        await this.uploadFile(file, e.target.files.length);
      }
    },
    convertFileSize(fileSize) {
      var formattedFileSize = ''
      if (fileSize < 1024) {
        formattedFileSize = fileSize + ' bytes';
      } else if (fileSize < 1048576) { // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + ' KB';
      } else if (fileSize < 1073741824) { // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + ' MB';
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + ' GB';
      }
      return formattedFileSize
    },
    async uploadFile(file, howManyFiles) {
      this.$Progress.start();
      this.disabled = true;
      const bodyFormData = new FormData();
      bodyFormData.append('accessToken', this.accessToken);
      bodyFormData.append('accessUsername', this.accessUsername);
      bodyFormData.append('media', file);
      var postUrl = appConfig.APIHostAdmin + 'controller/media/saveMediaFile'
      try {
        const response = await axios.post(postUrl, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        const resData = response.data;
        if (resData.status === 200) {
          // Add the URL to your array or handle the success as needed
          this.formSuccessData.transactionReceipt.push(
            {
              'file_name':file.name,
              'file_size': this.convertFileSize(file.size),
              'link': resData.media_path
            })
          
          this.currentFiles = this.currentFiles + 1
          this.uploadHowManyPerTime = this.uploadHowManyPerTime + 1
          if (howManyFiles==this.uploadHowManyPerTime){
            document.getElementById('formFile').value = ''
            this.uploadHowManyPerTime = 0
          }
        } else if (resData.status === 440) {
          // Handle 440 status code
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          localStorage.clear();
          this.$router.push({
            path: '/login',
          });
        } else {
          // Handle other status codes
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
        }
      } catch (error) {
        // Handle the error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
      } finally {
        this.disabled = false;
        this.uploadProgress = 0;
        this.$Progress.finish();
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formSuccessData[parentModel] = numericValue;
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    getPgList(){
      console.log("getPgList")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername",  this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getPgListShort',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedPg = pgDefault
            this.pgList = [this.selectedPg]
            resData.pg_list.forEach(element => {
              if (element.status!=='disabled' && element.supported_type.includes('manual_payout')){
                this.pgList.push({
                  id: element.id,
                  payment_gateway:`${element.payment_gateway_name} (${element.currency})`,
                  payment_gateway_name: element.payment_gateway_name,
                  payment_gateway_code: element.payment_gateway_code,
                  currency: element.currency,
                  pg_balance: element.pg_balance,
                  payout_rate: element.payout_rate,
                  payout_extra_charges: element.payout_extra_charges,
                  status:(element.status  === 'activated') ? 1 : 0,
                });
              }
            })
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    showModal() {
      this.$refs['modal-update-payout'].show()
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      if (this.data.updateData.status=='pending'){
        this.seletedStatus = 'reserved'
      }
      if (this.data.updateData.status=='reserved'){
        this.seletedStatus = 'complete'
      }
      this.getPgList();
    },
    startFiles() {
      this.fileCompressing = true;
    },
    currentImageSelectedFunc(index) {
      console.log("seleted" + index);
      this.currentImageSelected = index;
      if (this.currentImageSelected == 'front') {
        this.$refs.fileInput.trigger();
      } else if (this.currentImageSelected == 'back') {
        this.$refs.fileInput2.trigger();
      }
    },
    viewAllFiles(res) {
      this.fileCompressing = false;
      for (let n of res) {
        // this.ShakeCampaignBanner = n.img;
        const myFile = new File([this.DataURIToBlob(n.img)], n.name, {
          type: "image/jpeg",
        });
        if (this.currentImageSelected == 'front') {
          this.formSuccessData.transactionReceipt = n.img;
          this.formSuccessData.transactionReceiptFile = myFile;
          this.formSuccessData.transactionReceiptFilename = n.name
          console.log(myFile)
        } else if (this.currentImageSelected == 'back') {
          // this.formSuccessData.transactionReceipt2 = n.img;
          // this.formSuccessData.transactionReceiptFile2 = myFile;
          // this.formSuccessData.transactionReceiptFilename2 = n.name
          // console.log(myFile)
        }
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], {
        type: mimeString
      });
    },
    
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.seletedStatus = ""
      this.formRejectedData.reasonForRejected = ""
      this.formSuccessData.transactionId = ""
      this.formSuccessData.transactionRemarks = ""
      this.formSuccessData.transactionReceipt = []
      this.formSuccessData.paymentGateway=""
    },
    updatePg() {
      if (this.seletedStatus=='complete' && this.selectedPg.payment_gateway_code !=='') {
        this.formSuccessData.paymentGateway=this.selectedPg
      }else{
        this.formSuccessData.paymentGateway=""
      }
    },
    updateSettlement() {
      this.modalSubmit = true
      if (this.seletedStatus == 'rejected') {
        this.$v.formRejectedData.$touch();
        if (this.$v.formRejectedData.$invalid) {
          return;
        }
      }
      if (this.seletedStatus == 'complete') {
        this.$v.formSuccessData.$touch();
        if (this.$v.formSuccessData.$invalid) {
          return;
        }
      }
      this.$Progress.start();
      this.modalLoading = true;
      var bodyFormData = new FormData();
      const files = []
      this.formSuccessData.transactionReceipt.forEach(element => {
        files.push(element.link)
      });
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("payoutId", this.updateData.payout_id);
      bodyFormData.append("payoutDbCode", this.updateData.payout_db_code);
      bodyFormData.append("status", this.seletedStatus);
      if (this.seletedStatus == 'rejected') {
        bodyFormData.append("rejectedReason", this.formRejectedData.reasonForRejected);
      }
      if (this.seletedStatus == 'complete') {
        bodyFormData.append('paymentGateway', JSON.stringify(this.formSuccessData.paymentGateway));
        bodyFormData.append("transactionId", this.formSuccessData.transactionId);
        bodyFormData.append("transactionRemark", this.formSuccessData.transactionRemarks);
        bodyFormData.append("bankReceiptList", JSON.stringify(files));
      }
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/updatePayoutStatus',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        this.returnData = []
        var resData = response.data;
        if (resData.status == 200) {
          console.log(resData)
          Swal.fire({
            icon: 'success',
            title: 'Payout Status Updated',
            html: 'Your payout status has been successfully updated!',
            confirmButtonColor: '#FA6541',
            confirmButtonText: this.$t('siteLang.Done')
          }).then(() => {
            this.$refs['modal-update-payout'].hide()
            if (this.seletedStatus == 'pending') {
              this.$emit('callParentFunction');
            } else if (this.seletedStatus == 'reserved') {
              this.$emit('callParentFunction');
            } else if (this.seletedStatus == 'complete') {
              this.$router.push({
                name: "payout",
              });
            } else if (this.seletedStatus == 'rejected') {
              this.$router.push({
                name: "payout",
              });
            }
          })
        } else if (resData.status == 401) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.$refs.commonFunc.clearData()
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        this.modalLoading = false;
        this.modalSubmit = false
        this.$Progress.finish();
      }).catch((error) => {
        this.modalLoading = false;
        this.modalSubmit = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>